<!-- =========================================================================================
  File Name: UserEditTabSocial.vue
  Description: User Edit Social Tab content
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="data-list-list-view" class="data-list-container">
    <vs-prompt
      v-bind:title="$t('New Hospital')"
      :cancel-text="$t('Cancel')"
      :accept-text="$t('Save')"
      @cancel="clearHospital"
      @accept="AddHospital"
      @close="clearHospital"
      :is-valid="validateHospitalForm"
      :active.sync="activeHospitalPrompt"
    >
      <VuePerfectScrollbar
        class="scroll-area p-4"
        :settings="settings"
        :key="$vs.rtl"
      >
        <form @submit.prevent style="max-height: 75vh">
          <div class="mt-5 vx-row w-full " style="padding-left:20px">
            <div class="w-full">
              <label class="text-sm opacity-75">{{ $t("Hospitals") }}</label>
              <v-select
                label="Name"
                class=" w-full"
                v-model="hospitalModel.Hospital"
                :options="Hospitals"
                :reduce="value => value"
                append-to-body
              />
            </div>
          </div>
        </form>
      </VuePerfectScrollbar>
    </vs-prompt>

    <div>
      <div class="vx-row">
        <div class="vx-col lg:w-2/2 w-full mt-5">
          <vx-card v-bind:title="$t('User Hospitals')" class="mb-base">
            <vs-button
              type="border"
              class="mb-4 mt-4"
              icon-pack="feather"
              icon="icon-plus"
              @click="activeHospitalPrompt = true"
              >{{ $t("Add hospital") }}
            </vs-button>

            <vs-table
              ref="table"
              pagination
              :max-items="5"
              :data="HospitalList"
            >
              <template slot="thead">
                <vs-th>{{ $t("NameEn") }} </vs-th>
                <vs-th> {{ $t("NameEn") }}</vs-th>
                <vs-th> {{ $t("NameFr") }}</vs-th>
                <vs-th>{{ $t("Action") }}</vs-th>
              </template>

              <template slot-scope="{ data }">
                <tbody>
                  <vs-tr
                    :data="tr"
                    :key="indextr"
                    v-for="(tr, indextr) in data"
                    @click="editModule(tr)"
                  >
                    <vs-td>
                      <p class="product-name font-medium truncate">
                        {{ tr.Hospital.NameAR }}
                      </p>
                    </vs-td>
                    <vs-td>
                      <p class="product-name font-medium truncate">
                        {{ tr.Hospital.NameEN }}
                      </p>
                    </vs-td>
                    <vs-td>
                      <p class="product-name font-medium truncate">
                        {{ tr.Hospital.NameFR }}
                      </p>
                    </vs-td>
                    <vs-td class="whitespace-no-wrap">
                      <div class="vx-row">
                       
                        <feather-icon
                          icon="TrashIcon"
                          svgClasses="w-5 h-5 hover:text-danger stroke-current"
                          class="m-2 "
                          @click="openConfirm(tr)"
                        />
                      </div>
                    </vs-td>
                  </vs-tr>
                </tbody>
              </template>
            </vs-table>
          </vx-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { domain, mainSetting } from "@/gloabelConstant.js";
import vSelect from "vue-select";

export default {
  components: {
    VuePerfectScrollbar,
    vSelect
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      baseURL: domain,
      UploadRequestHeader: mainSetting.UploadRequestHeader,
      uploadUrl: domain + "API/Common/UploadFile",
      activeHospitalPrompt: false,
      activePrompt: false,
      UserModel: this.data,
      HospitalList:[],
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.3
      },
      hospitalModel: {},
      dataForDeleted: {},
      edit: false
    };
  },
  computed: {
     Hospitals() {
      return this.$store.state.HospitalList.Hospitals;
    },
    validateHospitalForm(){
      return this.hospitalModel.Hospital!=null
    }
  },
  methods: {
    AddHospital(){
      this.hospitalModel.UserID = this.$route.params.ID;
      this.hospitalModel.HospitalID=this.hospitalModel.Hospital.ID;
      this.HospitalList.push(this.hospitalModel);
      this.UserModel.UserHospitals = this.HospitalList;
      this.clearHospital();
    },
    clearHospital(){
      this.hospitalModel ={};
    },
    openConfirm(data) {
      this.rowDataForDelete = data;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: this.$t("Confirm"),
        text: this.$t("Delete This record ?"),
        accept: this.acceptAlert,
        acceptText: this.$t("Delete"),
        cancelText: this.$t("Cancel")
      });
    },
    acceptAlert() {
      this.deleteHospital(this.rowDataForDelete);
    },
    deleteHospital(data){
         if (data.ID > 0) {
        this.$store.dispatch("UserList/DeleteUserHospital", data.ID);
      }
      const ItemIndex = this.UserModel.UserHospitals.findIndex(
        p => p.DoctorID == data.DoctorID
      );
      this.UserModel.UserHospitals.splice(ItemIndex, 1);
      this.HospitalList = this.UserModel.UserHospitals
      window.showDeleteSuccess();
    
    }
  },
  created() {
    if (this.UserModel.UserHospitals.length > 0) {
      this.HospitalList = this.UserModel.UserHospitals;
    }
  }
};
</script>
