<!-- =========================================================================================
  File Name: UserEditTabSocial.vue
  Description: User Edit Social Tab content
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="data-list-list-view" class="data-list-container">
    <vs-prompt
      v-bind:title="$t('New Hospital Location')"
      :cancel-text="$t('Cancel')"
      :accept-text="$t('Save')"
      @cancel="clearMaterial"
      @accept="SaveMaterial"
      @close="clearMaterial"
      :is-valid="validateMaterialForm"
      :active.sync="activeMaterialPrompt"
    >
      <VuePerfectScrollbar
        class="scroll-area p-4"
        :settings="settings"
        :key="$vs.rtl"
      >
        <form @submit.prevent style="max-height: 75vh">
          <div class="vx-col flex-1">
            <label class="text-sm opacity-75">{{ $t("MaterialType") }}</label>
            <v-select
              label="label"
              class="w-full"
              v-model="MaterialItem.MaterialTypeID"
              :options="MaterialTypes"
              :reduce="value => value.value"
            />
          </div>

          <div class="vx-col flex-1">
            <vs-input
              :label="$t('Description')"
              v-model="MaterialItem.DescriptionOfAttachment"
              class="mt-5 w-full"
              :data-vv-as="$t('Description')"
              name="Description"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('Description')"
              >{{ errors.first("Description") }}</span
            >
          </div>
          <div class="vx-row flex-1">
            <vs-checkbox
              :label="$t('IsPublic')"
              v-model="MaterialItem.IsPublic"
              class="mt-5 w-full"
              value="true"
              >{{ $t("IsPublic") }}</vs-checkbox
            >
          </div>
          <div class="vx-row flex-1">
            <vs-checkbox
              :label="$t('IsFree')"
              v-model="MaterialItem.IsFree"
              class="mt-5 w-full"
              value="true"
              >{{ $t("IsFree") }}</vs-checkbox
            >
          </div>
          <div class="vx-col flex-1" v-if="MaterialItem.IsFree != true">
            <vs-input
              :label="$t('Cost')"
              v-model="MaterialItem.Cost"
              class="mt-5 w-full"
              :data-vv-as="$t('Cost')"
              type="number"
              name="Cost"
            />
            <span class="text-danger text-sm" v-show="errors.has('Cost')">{{
              errors.first("Cost")
            }}</span>
          </div>
          <vs-upload
            v-if="MaterialItem.MaterialTypeID != 5"
            automatic
            :limit="1"
            :action="uploadUrl"
            :headers="UploadRequestHeader"
            @on-success="successUpload"
          />
          <vs-input
            v-if="MaterialItem.MaterialTypeID == 5"
            v-model="MaterialItem.URL"
            class="mt-5 w-full"
            :data-vv-as="$t('URL')"
            name="URL"
          />
        </form>
      </VuePerfectScrollbar>
    </vs-prompt>

    <vs-prompt
      v-bind:title="$t('New Hospital Location')"
      @cancel="clearModuleModal"
      @accept="SaveModule"
      @close="clearModuleModal"
      :is-valid="validateModuleForm"
      :active.sync="activePrompt"
      :cancel-text="$t('Cancel')"
      :accept-text="$t('Save')"
    >
      <VuePerfectScrollbar
        class="scroll-area p-4"
        :settings="settings"
        :key="$vs.rtl"
      >
        <form @submit.prevent style="max-height: 75vh">
          <div class="vx-col w-full">
            <label class="text-sm opacity-75">{{ $t("NameAr") }}</label>
            <vs-input
              v-model="Module.NameAr"
              class="w-full"
              name="NameAr"
              :data-vv-as="$t('NameAr')"
              v-validate="'required'"
            />
            <span class="text-danger text-sm" v-show="errors.has('NameAr')">{{
              errors.first("NameAr")
            }}</span>
          </div>

          <div class="vx-col w-full">
            <label class="text-sm opacity-75">{{ $t("NameEn") }}</label>

            <vs-input
              v-model="Module.NameEn"
              :data-vv-as="$t('NameEn')"
              class="w-full"
              name="NameEn"
              v-validate="'required'"
            />
            <span class="text-danger text-sm" v-show="errors.has('NameEn')">{{
              errors.first("NameEn")
            }}</span>
          </div>

          <div class="vx-col w-full">
            <label class="text-sm opacity-75">{{ $t("NameFr") }}</label>

            <vs-input
              v-model="Module.NameFr"
              class="w-full"
              :data-vv-as="$t('NameFr')"
              name="NameFr"
              v-validate="'required'"
            />
            <span class="text-danger text-sm" v-show="errors.has('NameFr')">{{
              errors.first("NameFr")
            }}</span>
          </div>
        </form>
      </VuePerfectScrollbar>
    </vs-prompt>

    <div>
      <div class="vx-row">
        <div class="vx-col lg:w-2/2 w-full mt-5">
          <vx-card v-bind:title="$t('User Hospital Locations')" class="mb-base">
            <vs-button
              type="border"
              class="mb-4 mt-4"
              icon-pack="feather"
              icon="icon-plus"
              :disabled="moduleIsActive"
              @click="
                clearMaterial();
                activeMaterialPrompt = true;
              "
              >{{ $t("Add New hospital location") }}
            </vs-button>
            <vs-table
              ref="table"
              pagination
              :max-items="itemsPerPage"
              :data="UserModel.UserHospitalLocations"
            >
              <template slot="thead">
                <vs-th>{{ $t("MaterialType") }}</vs-th>
                <vs-th>{{ $t("Description") }}</vs-th>
                <vs-th>{{ $t("IsPublic") }}</vs-th>
                <vs-th>{{ $t("IsFree") }}</vs-th>

                <vs-th>{{ $t("Cost") }}</vs-th>

                <vs-th>{{ $t("Action") }}</vs-th>
              </template>

              <template slot-scope="{ data }">
                <tbody>
                  <vs-tr
                    :data="tr"
                    :key="indextr"
                    v-for="(tr, indextr) in data"
                  >
                    <vs-td>
                      <p
                        class="product-name font-medium truncate"
                        v-if="tr.MaterialTypeID == 1"
                      >
                        {{ $t("Pdf") }}
                      </p>
                      <p
                        class="product-name font-medium truncate"
                        v-if="tr.MaterialTypeID == 2"
                      >
                        {{ $t("Video") }}
                      </p>
                      <p
                        class="product-name font-medium truncate"
                        v-if="tr.MaterialTypeID == 3"
                      >
                        {{ $t("Image") }}
                      </p>
                      <p
                        class="product-name font-medium truncate"
                        v-if="tr.MaterialTypeID == 4"
                      >
                        {{ $t("Document") }}
                      </p>
                      <p
                        class="product-name font-medium truncate"
                        v-if="tr.MaterialTypeID == 5"
                      >
                        {{ $t("Link") }}
                      </p>
                    </vs-td>
                    <vs-td>
                      <p class="product-name font-medium truncate">
                        {{ tr.DescriptionOfAttachment }}
                      </p>
                    </vs-td>
                    <vs-td>
                      <p class="product-name font-medium truncate">
                        {{ tr.IsPublic }}
                      </p>
                    </vs-td>
                    <vs-td>
                      <p class="product-name font-medium truncate">
                        {{ tr.IsFree }}
                      </p>
                    </vs-td>
                    <vs-td>
                      <p class="product-name font-medium truncate">
                        {{ tr.Cost }}
                      </p>
                    </vs-td>
                    <!-- <vs-td>
                       <a :href="baseURL+tr.URL" download target="_blank"><feather-icon icon="DownloadIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2"  /></a>
                        </vs-td> -->
                    <vs-td class="whitespace-no-wrap">
                      <a
                        v-if="tr.MaterialTypeID != 5"
                        :href="baseURL + tr.URL"
                        download
                        target="_blank"
                        ><feather-icon
                          icon="DownloadIcon"
                          svgClasses="w-5 h-5 hover:text-danger stroke-current"
                          class="ml-2"
                      /></a>
                      <a
                        v-if="tr.MaterialTypeID == 5"
                        :href="tr.URL"
                        download
                        target="_blank"
                        >{{ $t("GoToLink") }}</a
                      >
                      <vs-button
                        class="text-lg mx-2"
                        color="danger"
                        @click.stop="openMaterialConfirm(tr)"
                        >{{ $t("Delete") }}</vs-button
                      >
                    </vs-td>
                  </vs-tr>
                </tbody>
              </template>
            </vs-table>
          </vx-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { domain, mainSetting } from "@/gloabelConstant.js";
import vSelect from "vue-select";
export default {
  components: {
    VuePerfectScrollbar,
    vSelect
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      baseURL: domain,
      UploadRequestHeader: mainSetting.UploadRequestHeader,
      uploadUrl: domain + "API/Common/UploadFile",
      activeMaterialPrompt: false,
      activePrompt: false,
      UserModel: this.data,
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.3
      },
      Module: {},
      attachment: {},
      MaterialTypes: [],
      dataForDeleted: {},
      edit: false,
      listOfSubjectModule: [],
      moduleItem: {},
      MaterialItem: {},
      moduleMaterials: [],
      moduleIsActive: false
    };
  },
  computed: {
    validateModuleForm() {
      return this.Module.NameEn && this.NameAr;
    },
    getListOfSubjectModule() {
      //return this.listOfSubjectModule
      return this.UserModel.SubjectStageYearModules;
    },
    validateMaterialForm() {
      return this.MaterialItem.DescriptionOfAttachment != undefined;
    }
  },
  methods: {
    SetMaterialTypes() {
      if (localStorage.getItem("SaveLang") === "ar") {
        this.MaterialTypes = [
          { label: "بي دي إف", value: 1 },
          { label: "فيديو", value: 2 },
          { label: "صورة", value: 3 },
          { label: "ملف", value: 4 },
          { label: "لينك", value: 5 }
        ];
      } else {
        this.MaterialTypes = [
          { label: "Pdf", value: 1 },
          { label: "Video", value: 2 },
          { label: "Image", value: 3 },
          { label: "Document", value: 4 },
          { label: "Link", value: 5 }
        ];
      }
    },
    SaveMaterial() {
      if (this.MaterialItem.URL != undefined)
        this.deleteMaterialLocal(this.MaterialItem.URL);

      this.moduleMaterials.unshift(this.MaterialItem);
    },

    deleteMaterial(data) {
      if (data.ID != undefined)
        this.$store.dispatch(
          "UserList/removeSubjectStageYearModuleAttachment",
          data.ID
        );

      this.deleteMaterialLocal(data.URL)
        .then(() => {
          window.showDeleteSuccess();
          this.$vs.loading.close();
        })
        .catch(err => {
          if (err.response.status == 400) {
            window.showDeleteFailed(err.response.data.message);
          } else {
            window.showDeleteFailed("");
          }
          this.$vs.loading.close();
          //console.error(err);
        });
    },
    deleteMaterialLocal(materialURL) {
      const MaterialIndex = this.moduleMaterials.findIndex(
        p => p.URL == materialURL
      );
      if (MaterialIndex != -1) this.moduleMaterials.splice(MaterialIndex, 1);
    },
    clearMaterial() {
      this.MaterialItem = {};
    },

    viewMaterial(moduleItem) {
      this.moduleIsActive = false;
      moduleItem.IsActive = true;

      if (moduleItem.SubjectStageYearModuleAttachments == undefined)
        moduleItem.SubjectStageYearModuleAttachments = [];
      this.moduleMaterials = moduleItem.SubjectStageYearModuleAttachments;
      moduleItem.SubjectStageYearModuleAttachments.map(function(val) {
        val.IsActive = false;
        return val;
      });
    },
    //  editMaterial(data)
    // {
    //    this.MaterialItem = JSON.parse(JSON.stringify(data));
    //     this.MaterialItem.OldName= this.MaterialItem.Name;
    //    this.activeMaterialPrompt=true;
    // },
    successUpload(event) {
      this.MaterialItem.URL = event.target.responseText;
      window.showSuccess();
    },
    SaveModule() {
      if (this.edit) {
        this.deleteModule(this.dataForDeleted);
        this.edit = false;
      }
      const isExist = this.UserModel.SubjectStageYearModules.findIndex(
        p => p.NameEn == this.Module.NameEn
      );
      if (isExist >= 0) {
        // this.colorAlert = color
        this.$vs.dialog({
          color: "warning",
          title: `Dialog - warning`,
          text: "This Module Already Exists"
        });
        this.Module = {};
        return;
      }

      this.listOfSubjectModule.push(this.Module);
      this.UserModel.SubjectStageYearModules = this.listOfSubjectModule;
      //this.deletefacility(this.facility);
      // this.$store.commit("classRoomList/ADD_Facility", this.facility);
      this.clearModuleModal();
    },

    clearModuleModal() {
      this.Module = {};
    },
    openMaterialConfirm(data) {
      this.rowDataForDelete = data;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: this.$t("Confirm"),
        text: this.$t("DeleteConfirmText"),
        accept: this.acceptMaterialAlert,
        acceptText: this.$t("Delete"),
        cancelText: this.$t("Cancel")
      });
    },
    openConfirm(data) {
      this.rowDataForDelete = data;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: this.$t("Confirm"),
        text: this.$t("DeleteConfirmText"),
        accept: this.acceptAlert,
        acceptText: this.$t("Delete"),
        cancelText: this.$t("Cancel")
      });
    },
    acceptAlert() {
      this.deleteModule(this.rowDataForDelete);
    },
    acceptMaterialAlert() {
      this.deleteMaterial(this.rowDataForDelete);
    },
    deleteModule(data) {
      if (data.ID > 0) {
        this.$store
          .dispatch("UserList/removeModule", data.ID)
          .then(() => {
            window.showDeleteSuccess();
            this.$vs.loading.close();
          })
          .catch(err => {
            if (err.response.status == 400) {
              window.showDeleteFailed(err.response.data.message);
            } else {
              window.showDeleteFailed("");
            }
            this.$vs.loading.close();
            //console.error(err);
          });
      }
      const ItemIndex = this.UserModel.SubjectStageYearModules.findIndex(
        p => p.NameEn == data.NameEn
      );
      //if(ItemIndex!=-1)
      this.UserModel.SubjectStageYearModules.splice(ItemIndex, 1);
    },
    editModule(data) {
      this.edit = true;
      this.Module = JSON.parse(JSON.stringify(data));
      this.dataForDeleted = this.Module;
      this.activePrompt = true;
    }
  },
  created() {
    this.SetMaterialTypes();
    if (this.UserModel.SubjectStageYearModules.length > 0) {
      this.listOfSubjectModule = this.UserModel.SubjectStageYearModules;
    }
  }
};
</script>
