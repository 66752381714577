<!-- =========================================================================================
  File Name: UserEditTabInformation.vue
  Description: User Edit Information Tab content
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="add-edit-main">
   
      <div>

        <!-- <div class="modify-img flex justify-between mt-5">
            <input
              type="file"
              class="hidden"
              ref="updateImgInput"
              @change="updateCurrImg"
              accept="image/*"
            />
            <vs-button class="mr-4" type="flat" @click="$refs.updateImgInput.click()">{{
              $t("UpdateImage")
            }}</vs-button>
            <vs-button type="flat" color="#999" @click="UserModel.ProfileImagePath = null">{{
              $t("RemoveImage")
            }}</vs-button>
          </div> -->

        <div>
          <img
            v-if="UserModel.ProfileImagePath"
            :src="baseURL + UserModel.ProfileImagePath"
            alt="img"
            width="150"
            height="150"
          />
          <!-- <vs-upload
            v-else
            automatic
            :limit="1"
            :action="baseURL + 'api/Common/UploadFile'"
            :headers="UploadRequestHeader"
            @on-success="successUpload"
          /> -->
          <input
              type="file"
              class="hidden"
              ref="updateImgInput"
              @change="updateCurrImg"
              accept="image/*"
            />
          <vs-button class="mr-4" type="flat" @click="$refs.updateImgInput.click()">{{
              $t("UpdateImage")
            }}</vs-button>

          <!-- <vs-upload
            v-else
            automatic
            :limit="1"
            @change="updateCurrImg"
            :headers="UploadRequestHeader"
            @on-success="successUpload"
          /> -->
        </div> 
         <div class="vx-row">
          <!-- <vs-button
            class="ml-8"
            type="flat"
            v-if="UserModel.ProfileImagePath"
            @click="UserModel.ProfileImagePath = null"
            >{{ $t("UploadDocument") }}
          </vs-button > -->
        </div> 
      </div>
      <div class="vx-row">
        <div class="vx-col md:w-1/3 w-full  ">
          <label class="text-sm opacity-75">{{ $t("Role") }}</label>
          <v-select
            :label="$t('Name')"
            class="w-full"
            v-model="UserModel.RoleName"
            :options="roles"
            :reduce="Name => Name.Name"
            v-validate="'required'"
            @input="OnChangeRoleName"
            append-to-body
          />
          <span class="text-danger text-sm" v-show="errors.has('Role')">{{
            errors.first("Role")
          }}</span>
        </div>
        <div
          v-show="UserModel.RoleName == 'Doctor'"
          class="vx-col md:w-1/3  w-full "
        >
          <label class="text-sm opacity-75">{{ $t("doctors") }}</label>
          <v-select
            label="Name"
            class="w-full"
            v-model="UserModel.DoctorID"
            :options="doctors"
            :reduce="ID => ID.ID"
          />
        </div>
        <div
          v-show="UserModel.RoleName == 'Patient'"
          class="vx-col md:w-1/3  w-full"
        >
          <label class="text-sm opacity-75">{{ $t("Patients") }}</label>
          <v-select
            label="Name"
            class="w-full"
            v-model="UserModel.PatientID"
            :options="Patients"
            :reduce="ID => ID.ID"
          />
        </div>
           <div
          v-show="UserModel.RoleName == 'Hospital'"
          class="vx-col md:w-1/3  w-full"
        >
          <label class="text-sm opacity-75">{{ $t("Hospitals") }}</label>
          <v-select
            label="Name"
            class="w-full"
            v-model="UserModel.HospitalID"
            :options="Hospitals"
            :reduce="ID => ID.ID"
          />
        </div>

         <div class="vx-col md:w-1/3 sm:w-1/2 w-full mb-4" v-show ="UserModel.RoleName == 'HospitalGroup'">
          <label class="text-sm opacity-75">{{ $t("Hospital Groups") }}</label>

          <v-select
            label="Name"
            class="mw-full"
            v-model="UserModel.HospitalGroupID"
            :options="HospitalGroups"
              append-to-body
            :reduce="(ID) => ID.ID"
          />
        </div>

        <div class="vx-col md:w-1/3 w-full ">
          <label class="text-sm opacity-75">{{ $t("Full Name") }}</label>
          <vs-input
            v-model="UserModel.FullName"
            class=" w-full"
            name="Full Name"
            :data-vv-as="$t('Full Name')"
            v-validate="'required'"
          />
          <span class="text-danger text-sm" v-show="errors.has('Full Name')">{{
            errors.first("Full Name")
          }}</span>
        </div>

        <div class="vx-col md:w-1/3 w-full ">
          <label class="text-sm opacity-75">{{ $t("Email") }}</label>
          <vs-input
            v-model="UserModel.Email"
            class=" w-full"
            :data-vv-as="$t('Email')"
            name="Email"
            v-validate="'required'"
          />
          <span class="text-danger text-sm" v-show="errors.has('Email')">{{
            errors.first("Email")
          }}</span>
        </div>


        <div class="vx-col md:w-1/3 w-full ">
          <label class="text-sm opacity-75">{{ $t("Phone") }}</label>
          <vs-input
            v-model="UserModel.PhoneNumber"
            class=" w-full"
            name="Phone"
            v-validate="'required'"
          />
          <span class="text-danger text-sm" v-show="errors.has('Phone')">{{
            errors.first("Phone")
          }}</span>
        </div>
         
         <div class="vx-col md:w-1/3 w-full ">
          <label class="text-sm opacity-75">{{ $t("User Name") }}</label>
          <vs-input
            v-model="UserModel.UserName"
            class=" w-full"
            name="UserName"
            v-validate="'required'"
          />
          <span class="text-danger text-sm" v-show="errors.has('UserName')">{{
            errors.first("UserName")
          }}</span>
        </div>

        <div class="vx-col md:w-1/3 w-full  " v-if="!this.$route.params.ID">
          <label class="text-sm opacity-75">{{ $t("Password") }}</label>
          <vs-input
            v-model="UserModel.Password"
            class=" w-full"
            @input="checkPassword"
            name="password"
            type="password"
            v-validate="'required|min:6'"
          />
          <span class="text-danger text-sm" v-show="errors.has('password')">{{
            errors.first("password")
          }}</span>
        </div>
        <div class="vx-row md:w-1/2 w-full mt-4 ">
        <div class="vx-col md:w-1/3 w-full  " >
          <vs-checkbox color="success" class="mt-5" v-model="UserModel.IsActive"
            >Is Active</vs-checkbox
          >
        </div>
         <div class="vx-col md:w-1/3 w-full  ">
          <vs-checkbox color="success" class="mt-5" v-model="UserModel.IsConfirmed"
            >Is Confirmed</vs-checkbox
          >
        </div>

            <div class="vx-col md:w-1/3 w-full  ">
          <vs-checkbox color="success" class="mt-5" v-model="UserModel.CanWrite"
            > Can Write</vs-checkbox
          >
        </div>
        </div>
      </div>
    
  </div>
</template>

<script>
import vSelect from "vue-select";
import { domain, mainSetting } from "@/gloabelConstant.js";
import moduleHospitalGroup from "@/store/settings/HospitalGroup/moduleHospitalGroup.js";
import moduleDoctor from "@/store/doctor/moduleDoctor.js";
import moduleUser from "@/store/user/moduleUser.js";
import modulePatient from "@/store/Patient/modulePatient.js";

export default {
  components: {
    vSelect
  },
  props: {
    UserModel: {
      type: Object,
      required: true
    },
    viewLoginInfo: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      search: {},
      contains_eight_characters: false,
      contains_number: false,
      contains_uppercase: false,
      contains_special_character: false,
      Attachment: null,
      // uploadUrl: domain + "API/Course/UploadFile",
      UploadRequestHeader: mainSetting.UploadRequestHeader,
      baseURL: domain,
      update: false
    };
  },
  computed: {
    HospitalGroups() {
      return this.$store.state.HospitalGroupList.HospitalGroups;
    },
    roles() {
      return this.$store.state.UserList.roles;
    },
    doctors() {
      return this.$store.state.DoctorList.doctors;
    },
    Patients() {
      debugger
      return this.$store.state.patientList.patients;
    },
     Hospitals() {
      return this.$store.state.HospitalList.Hospitals;
    },
  },
  methods: {

    async updateCurrImg(input) {
      this.$vs.loading();
      debugger;
      if (input.target.files && input.target.files[0]) {
        const file = input.target.files[0];
        this.$store
          .dispatch("DoctorList/UploadImage", file)
          .then((res) => {
            if (res.status == 200) {
              debugger;
              this.UserModel.ProfileImagePath = res.data;
              this.$vs.loading.close();
              window.showSuccess();
            }
            this.$vs.loading.close();
          })
          .catch(() => {
            this.$vs.loading.close();
            window.showError();
          });
      }
    },
    successUpload() {
      this.$vs.notify({
        color: "success",
        title: "Upload Success",
        text: "Lorem ipsum dolor sit amet, consectetur",
      });
    },

    OnChangeRoleName() {
      if (this.UserModel.RoleName == "AccountManager") {
        this.$store.commit("UserList/CHANGE_ROLENAME", "Hospital");
      } else if (
        this.UserModel.RoleName == "Admin" ||
        this.UserModel.RoleName == "Doctor" ||
        this.UserModel.RoleName == "Finance" ||
        this.UserModel.RoleName == "Patient" ||
         this.UserModel.RoleName == "Hospital" ||
        this.UserModel.RoleName == "AgentsSupervisor"
      ) {
        this.$store.commit("UserList/CHANGE_ROLENAME", "Main");
      } else if (this.UserModel.RoleName == "PatientServiceAgent") {
        this.$store.commit("UserList/CHANGE_ROLENAME", "Patient");
      }
      else if (this.UserModel.RoleName == "HospitalGroup") {
        this.$store.commit("UserList/CHANGE_ROLENAME", "HospitalGroup");
      }
    },
    successUpload(event) {
      debugger;
      this.UserModel.ProfileImagePath = event.target.responseText;
      window.showAddSuccess();
    },
    checkPassword() {
      this.password_length = this.UserModel.Password.length;
      const format = /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;
      if (this.password_length > 8) {
        this.contains_eight_characters = true;
      } else {
        this.contains_eight_characters = false;
      }
      this.contains_number = /\d/.test(this.UserModel.Password);
      this.contains_uppercase = /[A-Z]/.test(this.UserModel.Password);
      this.contains_special_character = format.test(this.UserModel.Password);
      if (
        this.contains_eight_characters === true &&
        this.contains_special_character === true &&
        this.contains_uppercase === true &&
        this.contains_number === true
      ) {
        this.valid_password = true;
      } else {
        this.valid_password = false;
      }
    }
  },
  created() {
    if (!moduleHospitalGroup.isRegistered) {
      this.$store.registerModule("HospitalGroupList", moduleHospitalGroup);
      moduleHospitalGroup.isRegistered = true;
    }
    if (!modulePatient.isRegistered) {
      // this.$vs.loading();
      this.$store.registerModule("patientList", modulePatient);
      modulePatient.isRegistered = true;
    }
    this.$store.dispatch("patientList/SearchPatients", {});

    if (!moduleDoctor.isRegistered) {
      this.$store.registerModule("DoctorList", moduleDoctor);
      moduleDoctor.isRegistered = true;
    }
    this.$store.dispatch("DoctorList/GetAllDoctors", {});

    if (!moduleUser.isRegistered) {
      this.$store.registerModule("UserList", moduleUser);
      moduleUser.isRegistered = true;
    }
    this.$store.dispatch("UserList/GetAllRoles");

if(this.HospitalGroups.length==0)
      this.$store.dispatch("HospitalGroupList/GetAllHospitalGroups");

    if (this.$route.params.ID && !this.update) {
      this.$store
        .dispatch("UserList/GetUserById", this.$route.params.ID)

        .catch(err => {
          if (err.response.status === 404) {
            this.user_not_found = true;
            return;
          }
          //console.error(err)
        });
    }
  }
};
</script>
<style >
.vs__dropdown-menu{ 
  max-height: 230px!important;
}
</style>