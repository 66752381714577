<!-- =========================================================================================
  File Name: UserEdit.vue
  Description: User Edit Page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="page-user-edit">
    <feather-icon icon="ArrowLeftIcon" svgClasses="h-8" style="cursor: pointer" @click="$router.push('/user')" />
    <vs-alert color="danger" title="User Not Found" :active.sync="user_not_found">
      <span>User record with id: {{ $route.params.userId }} not found. </span>
      <span>
        <span>Check </span><router-link :to="{ name: 'page-user-list' }" class="text-inherit underline">All
          Users</router-link>
      </span>
    </vs-alert>

    <vx-card>
      <div slot="no-body" class="tabs-container px-6 pt-6">
        <vs-tabs class="tab-action-btn-fill-conatiner" style="margin-bottom: 220px">
          <vs-tab :label="$t('Main Information')" icon-pack="feather" icon="icon-info">
            <div class="tab-text">
              <add-edit-main class="mt-4" :UserModel="UserModel" :viewLoginInfo="false" />
            </div>
          </vs-tab>

        <!-- <vs-tab :label="$t('Hospitals')" icon-pack="feather" icon="icon-share-2"
          :disabled="RoleName != 'AccountManager'">
          <div class="tab-text">
            <add-edit-hospitals class="mt-4" :data="UserModel" />
          </div>
        </vs-tab> -->

        <!-- <vs-tab
              :label="$t('Hospital Locations')"
              icon-pack="feather"
              icon="icon-share-2"
              :disabled="RoleName == 'Main' || RoleName == 'Patient'"
            >
              <div class="tab-text">
                <add-edit-hospitalLocations class="mt-4" :data="UserModel" />
              </div>
            </vs-tab> -->

          <vs-tab :label="$t('Doctors')" icon-pack="feather" icon="icon-share-2"
            :disabled="RoleName != 'AccountManager' && RoleName != 'PatientServiceAgent' && RoleName != 'HospitalGroup'">
            <div class="tab-text">
              <add-edit-doctors class="mt-4" :data="UserModel" />
            </div>
          </vs-tab>

          <vs-tab :label="$t('Specialties')" icon-pack="feather" icon="icon-share-2"
            :disabled="RoleName != 'AccountManager' && RoleName != 'PatientServiceAgent' && RoleName != 'HospitalGroup'">
            <div class="tab-text">
              <add-edit-specialties class="mt-4" :data="UserModel" />
            </div>
          </vs-tab>

          <vs-tab :label="$t('Patient')" icon-pack="feather" icon="icon-share-2"
            :disabled="RoleName != 'PatientServiceAgent' && RoleName != 'HospitalGroup'">
            <div class="tab-text">
              <add-edit-patient class="mt-4" :data="UserModel" />
            </div>
          </vs-tab>

          <vs-tab :label="$t('Patient Locations')" icon-pack="feather" icon="icon-share-2"
            :disabled="RoleName != 'PatientServiceAgent' && RoleName != 'HospitalGroup'">
            <div class="tab-text">
              <add-edit-patientLocations class="mt-4" :data="UserModel" />
            </div>
          </vs-tab>

          <!-- Save & Reset Button -->
          <div class="vx-row mb-5">
            <div class="vx-col w-full">
              <div class="mt-8 flex flex-wrap items-center justify-end">
                <vs-button class="ml-4 mt-2" @click="save_changes" :disabled="!isFormValid">
                  {{ $t("Save") }}
                </vs-button>
                <vs-button class="ml-4 mt-2" type="border" color="danger" @click="Back()">
                  {{ $t("Cancel") }}</vs-button>
              </div>
            </div>
          </div>
        </vs-tabs>
      </div>
    </vx-card>
  </div>
</template>

<script>
import AddEditSpecialties from "./Add-Edit-Specialties.vue";
import AddEditDoctors from "./Add-Edit-Doctors.vue";
import AddEditPatient from "./Add-Edit-Patient.vue";

import AddEditMain from "./Add-Edit-Main.vue";
import AddEditHospitals from "./Add-Edit-Hospitals.vue";
import AddEditHospitalLocations from "./Add-Edit-HospitalLocations.vue";
import moduleUser from "@/store/user/moduleUser.js";
import AddEditPatientLocations from "./Add-Edit-PatientLocations.vue";
import moduleHospital from "@/store/Hospital/moduleHospital.js";

export default {
  components: {
    AddEditDoctors,
    AddEditPatient,
    AddEditHospitalLocations,
    AddEditHospitals,
    AddEditSpecialties,
    AddEditMain,
    AddEditPatientLocations,
  },
  computed: {
    isFormValid() {
      return !this.errors.any();
    },
    RoleName() {
      debugger
      return this.$store.state.UserList.RoleName;
    },
  },

  data() {
    return {
      UserModel: {
        RoleName: "",
        UserPatientLocations: [],
        UserSpecialties: [],
        UserHospitalLocations: [],
        UserHospitals: [],
        PatientID: 0,
      },
      user_not_found: false,
      activeTab: 0,
    };
  },
  watch: {},
  methods: {
    reset_data() {
      this.initValues();
    },
    Back() {
      this.$router.go(-1);
    },
    save_changes() {
      this.$validator.validateAll().then((result) => {
        this.$vs.loading();
        if (result) {
          if (this.$route.params.ID) {
            this.$store
              .dispatch("UserList/UpdateUser", this.UserModel)
              .then(() => {
                window.showUpdateSuccess();
                this.$vs.loading.close();
              })
              .catch((err) => {
                if (err.response.status == 400) {
                  window.showUpdateFailed(err.response.data.Message);
                } else {
                  window.showUpdateFailed("");
                }
                this.$vs.loading.close();
              });
            this.initValues();
            this.Back();
          } else {
            this.$store
              .dispatch("UserList/addItem", this.UserModel)
              .then(() => {
                window.showAddSuccess();
                this.initValues();
                this.Back();

                this.$vs.loading.close();
              })
              .catch((err) => {
                if (err.response.status == 400) {
                  window.showAddFailed(err.response.data.Message);
                } else {
                  window.showAddFailed("");
                }
                x;

                this.$vs.loading.close();
              });
          }
        }
      });
    },
    initValues() {
      this.UserModel = {
        UserPatientLocations: [],
        UserSpecialties: [],
        UserHospitalLocations: [],
        UserHospitals: [],
        PatientID: 0,
      };
    },
  },
  created() {
    if (!moduleUser.isRegistered) {
      this.$store.registerModule("UserList", moduleUser);
      moduleUser.isRegistered = true;
    }
    const ID = this.$route.params.ID;
    if (ID != undefined) {
      this.$vs.loading();
      this.$store
        .dispatch("UserList/GetUserById", ID)
        .then((res) => {
          this.$vs.loading.close();
          debugger;
          if (res.data.Data == undefined) {
            this.UserModel = res.data.Data;
          } else {
            this.UserModel = res.data.Data;
          }
          debugger
          this.$store.commit("UserList/CHANGE_ROLENAME", this.UserModel.RoleName);
          // if (this.UserModel.RoleName == "AccountManager") {
          //   this.$store.commit("UserList/CHANGE_ROLENAME", "Hospital");
          // } else if (
          //   this.UserModel.RoleName == "Admin" ||
          //   this.UserModel.RoleName == "Doctor" ||
          //   this.UserModel.RoleName == "Finance" ||
          //   this.UserModel.RoleName == "Patient" ||
          //   this.UserModel.RoleName == "AgentsSupervisor"
          // ) {
          //   this.$store.commit("UserList/CHANGE_ROLENAME", "Main");
          // } else if (this.UserModel.RoleName == "PatientServiceAgent") {
          //   this.$store.commit("UserList/CHANGE_ROLENAME", "Patient");
          // }
        })
        .catch((err) => {
          this.$vs.loading.close();
          if (err.response.status === 404) {
            this.user_not_found = true;
            return;
          }
        });
    }
    if (!moduleHospital.isRegistered) {
      this.$store.registerModule("HospitalList", moduleHospital);
      moduleHospital.isRegistered = true;
    }
    this.$store.dispatch("HospitalList/GetAllHospitals", {});
  },
};
</script>
<style>
.vs__dropdown-menu {
  z-index: 50000000000000;

  position: absolute;
}
</style>
