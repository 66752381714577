<!-- =========================================================================================
  File Name: UserEditTabSocial.vue
  Description: User Edit Social Tab content
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="data-list-list-view" class="data-list-container">
    <vs-prompt
      v-bind:title="$t('New Specialty')"
      @cancel="clearAttachmentModal"
      @accept="SaveAttachment"
      @close="clearAttachmentModal"
      :is-valid="validateAttachmentForm"
      :active.sync="activePrompt"
      :cancel-text="$t('Cancel')"
      :accept-text="$t('Save')"
    >
      <VuePerfectScrollbar
        class="scroll-area p-4"
        :settings="settings"
        :key="$vs.rtl"
      >
        <form @submit.prevent style="max-height:75vh">
          <div class="mt-5 vx-row w-full " style="padding-left:20px">
            <div class="w-full">
              <label class="text-sm opacity-75">{{ $t("Specialty") }}</label>
              <v-select
                label="Name"
                class=" w-full"
                v-model="attachment.Specialty"
                :options="Specialties"
                :reduce="value => value"
                append-to-body
              />
            </div> 
          </div>
        </form>  
      </VuePerfectScrollbar>
    </vs-prompt>

    <div>
      <div class="vx-row">
        <div class="vx-col lg:w-4/4 w-full">
          <vx-card v-bind:title="$t('Specialties')" class="mb-base">
            <vs-button
              type="border"
              class="mb-4 mt-4"
              icon-pack="feather"
              icon="icon-plus"
              @click="activePrompt = true"
              >{{ $t("Add Specialty") }}
            </vs-button>

            <vs-table
              ref="table"
              pagination
              :max-items="5"
              :data="UserModel.UserSpecialties"
            >
              <template slot="thead">
                <vs-th>{{ $t("NameAr") }}</vs-th>
                <vs-th>{{ $t("NameEn") }}</vs-th>
                <vs-th>{{ $t("Action") }}</vs-th>
              </template>

              <template slot-scope="{ data }">
                <tbody>
                  <vs-tr
                    :data="tr"
                    :key="indextr"
                    v-for="(tr, indextr) in data"
                  >
                    <vs-td class="product-name font-medium truncate">
                      {{ tr.Specialty.NameAR }}
                    </vs-td>
                    <vs-td>
                      <p class="product-name font-medium truncate">
                        {{ tr.Specialty.NameEN }}
                      </p>
                    </vs-td>

                    <vs-td class="whitespace-no-wrap">
                      <feather-icon
                        icon="TrashIcon"
                        svgClasses="w-5 h-5 hover:text-danger stroke-current"
                        class="m-2 "
                        @click="openConfirm(tr)"
                      />
                    </vs-td>
                  </vs-tr>
                </tbody>
              </template>
            </vs-table>
          </vx-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { domain, mainSetting } from "@/gloabelConstant.js";
export default {
  components: {
    VuePerfectScrollbar
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      baseURL: domain,
      UploadRequestHeader: mainSetting.UploadRequestHeader,
      activePrompt: false,
      UserModel: this.data,
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.3
      },
      Specialties: [],
      attachment: {},
      dataForDeleted: {},
      listOfSubjectAttachment: [],
      uploadUrl: domain + "API/Common/UploadFile"
    };
  },
  computed: {
    validateAttachmentForm() {
      return this.attachment.Specialty != undefined;
    },
    getListOfSubjectAttachment() {
      return this.UserModel.UserSpecialties;
    }
  },
  methods: {
    SetSpecialties() {
      this.$store
        .dispatch("UserList/GetAllSpecialties")
        .then(res => {
          this.Specialties = res.data.Data;
        })
        .catch(err => {
          if (err.response.status === 404) {
            this.user_not_found = true;
            return;
          }
        });
    },
    successUpload(event) {
      this.attachment.URL = event.target.responseText;
      window.showAddSuccess();
    },
    SaveAttachment() {
      this.attachment.UserID = this.$route.params.ID;
      this.attachment.SpecialtyID=this.attachment.Specialty.ID;
      this.listOfSubjectAttachment.push(this.attachment);
      this.UserModel.UserSpecialties = this.listOfSubjectAttachment;
      this.clearAttachmentModal();
    },
    clearAttachmentModal() {
      this.attachment = {};
    },
    openConfirm(data) {
      this.rowDataForDelete = data;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: this.$t("Confirm"),
        text: this.$t("Delete This record ?"),
        accept: this.acceptAlert,
        acceptText: this.$t("Delete"),
        cancelText: this.$t("Cancel")
      });
    },
    acceptAlert() {
      this.deleteAttachment(this.rowDataForDelete);
    },
    deleteAttachment(data) {
      if (data.ID > 0) {
        this.$store.dispatch("UserList/DeleteUserSpcialty", data.ID);
      }
      const ItemIndex = this.UserModel.UserSpecialties.findIndex(
        p => p.SpecialtyID == data.SpecialtyID
      );
      this.UserModel.UserSpecialties.splice(ItemIndex, 1);
      window.showDeleteSuccess();
    }
  },
  created() {
    this.SetSpecialties();
    if (this.UserModel.UserSpecialties.length > 0) {
      this.listOfSubjectAttachment = this.UserModel.UserSpecialties;
    }
  }
};
</script>
<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
